html, body {
    height: 100%;
    margin: 0;
}

body {
    display: flex;
    flex-direction: column; /* Ensure children are stacked */
    background-color: #f9f9f9; /* Set a background color */
}

.view-quotations-container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100vw; /* Full width of the viewport */
    height: 100vh; /* Full height of the viewport */
    margin: 0; /* No margin */
    font-family: Arial, sans-serif;
    overflow: auto; /* Enable scrolling if content exceeds viewport */
}

.button-container {
    display: flex; /* Use flex to center the button */
    justify-content: flex-end; /* Align to the right */
    margin-bottom: 20px; /* Add spacing below the button */
}

.view-button {
    background-color: #4CAF50; /* Green */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 16px;
    font-weight: bold;
}

.view-button:hover {
    background-color: #45a049; /* Darker green */
}

.quotations-table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 16px;
    table-layout: fixed; /* Ensures equal column width */
}

.quotations-table th, .quotations-table td {
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    width: 25%; /* Each column takes up equal space */
}

.quotations-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.quotation-row {
    transition: background-color 0.2s ease;
}

.quotation-row:hover {
    background-color: #f1f1f1; /* Light grey on hover */
}

.modal-overlay {
    background-color: rgba(0, 0, 0, 0.75); /* Dark semi-transparent background */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: #fff;
    padding: 20px; /* Padding around content */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 400px; /* Set a larger fixed width for the popup */
    max-width: 90vw; /* Allow some responsiveness */
    height: auto; /* Auto height to fit content */
    max-height: 90vh; /* Max height to handle overflow */
    overflow-y: auto; /* Scrollable content if needed */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.close-modal-button {
    background-color: #ff5f5f;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    align-self: flex-end;
}

.view-details-button {
    background-color: #2196F3; /* Blue */
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 14px;
    font-weight: bold;
}

.view-details-button:hover {
    background-color: #1976D2; /* Darker blue */
}

.error-message {
    color: red;
    margin: 10px 0;
}
.download-link {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }
  .quotations-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .quotations-table th,
  .quotations-table td {
    padding: 12px;
    text-align: left; /* Default alignment */
    border: 1px solid #ccc;
  }
  
  .quotations-table th {
    background-color: #f4f4f4;
  }
  .search-input {
    width: 300px; /* Set a fixed width for the search input */
    max-width: 20%; /* Allow some responsiveness */
    padding: 5px;
    font-size: 10px;
    border: 1px solid #ccc;
    border-radius: 2px;
    transition: border-color 0.3s ease;
    margin-bottom: 20px;
}

.search-input:focus {
    border-color: #66a6ff; /* Change border color on focus */
    outline: none; /* Remove default outline */
}