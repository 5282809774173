/* Reset margins and paddings */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Arial', sans-serif;
  background: linear-gradient(135deg, #3c67c1, #99c2e2); 
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Main container for the login form */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Styles for the login form */
.login-form {
  background-color: #ffffff;
  padding: 50px;
  border-radius: 20px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 400px;
  animation: fadeIn 0.5s ease-in-out; /* Animation on form appearance */
}

/* Heading style */
.login-form h2 {
  color: #66a6ff; /* Soft blue heading */
  font-size: 36px;
  margin-bottom: 30px;
  font-weight: bold;
  animation: slideIn 0.5s ease-in-out; /* Animation on heading */
}

/* Input field styles */
.input {
  width: 100%;
  padding: 15px;
  margin-bottom: 20px;
  border: 2px solid transparent;
  border-radius: 10px;
  background-color: #f0f4f8;
  color: #333;
  font-size: 16px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;
}

.input::placeholder {
  color: #aab0b7; /* Placeholder color */
}

/* Input field focus state */
.input:focus {
  outline: none;
  background-color: #e8f0fe; /* Light blue on focus */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  border-color: #66a6ff; /* Border color on focus */
}

/* Button styles */
.button {
  width: 100%;
  padding: 15px;
  background-color: #66a6ff; /* Button color */
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Button hover effect */
.button:hover {
  background-color: #5589db; /* Darker blue on hover */
}

/* Button active effect */
.button:active {
  transform: scale(0.98); /* Slightly shrink on click */
}

/* Error message styles */
.error-message {
  color: #ff4d4f; /* Error color */
  font-size: 14px;
  margin-top: 10px;
  animation: fadeIn 0.5s ease-in-out; /* Fade-in animation for error */
}

/* Password input container for toggle visibility */
.password-input-container {
  position: relative;
  margin-bottom: 16px; /* Adjust as needed */
}

/* Button for toggling password visibility */
.toggle-password-visibility {
  position: absolute;
  right: 10px; /* Adjust position */
  top: 50%; /* Center vertically */
  transform: translateY(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
}

/* Keyframe animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
