.view-plan-container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100vw; /* Full width of the viewport */
    height: 100vh; /* Full height of the viewport */
    margin: 0; /* No margin */
    font-family: Arial, sans-serif;
    overflow: auto; /* Enable scrolling if content exceeds viewport */
}

.button-container {
    display: flex; /* Use flex to center the button */
    justify-content: flex-end; /* Align to the right */
    margin-bottom: 20px; /* Add spacing below the button */
}

.add-more-button {
    background-color: #4CAF50; /* Green */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 16px;
    font-weight: bold;
}

.add-more-button:hover {
    background-color: #45a049; /* Darker green */
}

.plans-table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 16px;
}

.plans-table th, .plans-table td {
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    width: 40px;
    text-align: center;
}

.plans-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.plan-row {
    transition: background-color 0.2s ease;
}

.plan-row:hover {
    background-color: #f1f1f1; /* Light grey on hover */
}

.delete-button {
    background-color: #f44336; /* Red */
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 14px;
    font-weight: bold;
}

.delete-button:hover {
    background-color: #d32f2f; /* Darker red */
}

.modal {
    background: white;
    border-radius: 10px; /* Keep the border radius for rounded corners */
    padding: 15px; 
    max-height: 500px; /* Adjust max height if needed */
    margin: 20px auto; /* Center the modal */
    position: fixed;
    top: 20%; /* Adjust position if needed */
    left: 50%;
    transform: translate(-50%, -20%); /* Adjust position to truly center */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5); /* Keep the shadow for depth */
    z-index: 1001; /* Ensure it appears above other elements */
    overflow: auto; /* Allow scrolling if content is too large */
}

.close-modal-button {
    background-color: #f44336; /* Red */
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 14px;
    font-weight: bold;
    float: right; /* Align the button to the right */
}

.close-modal-button:hover {
    background-color: #d32f2f; /* Darker red */
}

.overlay {
    background: rgba(0, 0, 0, 0.75);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000; /* Ensure overlay is on top */
}
.edit-button {
    background-color: #2196F3; /* Blue */
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 14px;
    font-weight: bold;
    margin-right: 10px; /* Add spacing between edit and delete buttons */
}

.edit-button:hover {
    background-color: #1976D2; /* Darker blue */
}

