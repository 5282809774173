/* General Styles */
html,
body {
  height: 100%;
  margin: 0;
  font-family: "Arial", sans-serif;
  background-color: rgba(0, 0, 0, 0.5); /* Darker background for modal */
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.create-plan-container {
  width: 80vw; /* Smaller width for the modal */
  max-width: 970px; /* Reduced maximum width */
  height: auto;
  padding: 20px; /* Reduced padding */
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5); /* Darker shadow */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  z-index: 1000; /* Ensure it appears above other elements */
  margin: 0px;
}

h2 {
  text-align: center;
  color: #333;
  margin-bottom: 15px; /* Reduced margin */
  font-size: 18px; /* Reduced font size */
}

/* Plan Section Styles */
.plan-section {
  margin-bottom: 15px; /* Reduced margin */
  padding: 10px; /* Reduced padding */
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fafafa;
}

.dropdown,
.input-field {
  margin-bottom: 10px; /* Reduced margin */
}

label {
  font-weight: bold;
  margin-bottom: 5px; /* Reduced margin */
  display: block;
  font-size: 14px; /* Reduced font size */
}

input[type="number"],
select {
  width: 100%;
  padding: 8px; /* Reduced padding */
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px; /* Reduced font size */
  transition: border-color 0.3s;
}

input[type="number"]:focus,
select:focus {
  border-color: #66a6ff;
  outline: none;
}

.court-selection {
  margin: 10px 0; /* Reduced margin */
}

.court-counter {
  display: flex;
  align-items: center;
}

.counter-btn {
  background-color: #66a6ff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 6px 10px; /* Reduced padding */
  margin: 0 5px; /* Reduced margin */
  transition: background-color 0.3s;
  
}

.counter-btn:hover {
  background-color: #0056b3;
}

.submit-button {
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px; /* Reduced padding */
  width: 100%;
  font-size: 16px; /* Reduced font size */
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #218838;
}

.error-message {
  color: red;
  text-align: center;
  margin-bottom: 10px; /* Reduced margin */
  font-size: 14px; /* Reduced font size */
}

/* Additional Styles */
.plan-separator {
  border: 1px solid #ddd;
  margin: 10px 0; /* Reduced margin */
}
.court-counter {
  display: flex; /* Use flexbox layout */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  margin: 15px 0; /* Optional: Add some margin for spacing */
}

.counter-btn {
  background-color: #007bff; /* Example button background color */
  color: white; /* Example button text color */
  border: none; /* Remove default border */
  border-radius: 4px; /* Optional: rounded corners */
  padding: 10px 15px; /* Padding for the button */
  cursor: pointer; /* Change cursor to pointer on hover */
  font-size: 14px; /* Font size for the button */
  margin: 0 10px; /* Space between buttons and count */
  transition: background-color 0.3s; /* Smooth background color transition */
}

.counter-btn:hover {
  background-color: #0056b3; /* Darker background on hover */
}

.court-count {
  font-size: 20px; /* Adjust font size for the count display */
  margin: 0 10px; /* Space around the count */
}
.select-court{
  text-align: center;
}
.fixed-input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  background-color: #f0f0f0; /* Light gray background to indicate read-only */
  border: 1px solid #ccc;
  border-radius: 4px;
}/* Base Table Styles */
.time-price-table {
  margin: 20px 0;
}

.table-heading {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.price-table {
  width: 100%;
  border-collapse: collapse;
}

.table-header {
  text-align: left;
  padding: 8px 12px;
  background-color: #f0f0f0;
  font-weight: bold;
  border: 1px solid #ddd;
}

.table-row {
  transition: background-color 0.3s;
}

.table-row:hover {
  background-color: #f9f9f9;
}

.table-cell {
  padding: 8px 12px;
  border: 1px solid #ddd;
}

.time-select,
.price-input {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.time-select:focus,
.price-input:focus {
  border-color: #66a6ff;
  outline: none;
}

/* Action Buttons */
.actions-cell {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 21px;
}

.action-btn {
  background-color: #66a6ff;
  border: none;
  color: white;
  padding: 8px;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
}

.action-btn:disabled {
  background-color: #ccc;
}

.minus-btn {
  background-color: #e74c3c;
}

.plus-btn {
  background-color: #2ecc71;
}

.action-btn:hover {
  opacity: 0.8;
}

/* Weekend and Weekday Specific Styles */
.weekend-table {
  background-color: #f8f9fa;
}

.weekday-table {
  background-color: #fff;
}
