/* General Styles */
html, body {
  height: 100%; /* Full height for HTML and body */
  margin: 0; /* Remove default margin */
  font-family: Arial, sans-serif; /* Font style for the page */
  background-color: rgba(0, 0, 0, 0.5); /* Dark semi-transparent background for the modal overlay */
  width: 100%; /* Ensure full width */
  font-size: 18px; /* Increase overall font size */
}

/* Modal Overlay */
.modal-overlay {
  position: fixed; /* Fixed positioning for overlay */
  top: 0; /* Position at the top */
  left: 0; /* Position at the left */
  right: 0; /* Extend to the right */
  bottom: 0; /* Extend to the bottom */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  display: flex; /* Flexbox for centering */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  z-index: 1000; /* Make sure it is above other content */
}

.add-page-container {
  background-color: #fff; /* White background for the form */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Soft shadow for depth */
  padding: 20px; /* Padding around the container */
  box-sizing: border-box; /* Include padding in width */
}

h2 {
  text-align: center; /* Center the heading */
  color: #333; /* Dark text color */
  margin-bottom: 20px; /* Space below the heading */
}

.input-field {
  margin-bottom: 20px; /* Increased space between input fields */
}

label {
  font-weight: bold; /* Bold label text */
  margin-bottom: 10px; /* Space below labels */
  display: block; /* Make labels block elements */
}

input[type="text"],
input[type="number"],
textarea {
  width: 100%; /* Full width for inputs */
  padding: 15px; /* Increased padding inside inputs */
  border: 1px solid #ccc; /* Light border */
  border-radius: 4px; /* Rounded corners */
  font-size: 18px; /* Increase font size */
  transition: border-color 0.3s; /* Smooth transition for border color */
}

input[type="text"]:focus,
input[type="number"]:focus,
textarea:focus {
  border-color: #66a6ff; /* Change border color on focus */
  outline: none; /* Remove default outline */
}

textarea {
  resize: vertical; /* Allow vertical resize for text area */
  min-height: 150px; /* Increased minimum height for the text area */
}

.submit-button {
  background-color: #66a6ff; /* Button color */
  color: white; /* Text color */
  padding: 15px 20px; /* Increased padding for buttons */
  margin: 15px 0; /* Increased space around buttons */
  border: none; /* Remove border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor */
  transition: background-color 0.3s; /* Smooth transition */
  width: 100%; /* Full width */
  font-size: 18px; /* Increase font size for button */
}

.submit-button:hover {
  background-color: #5a99e6; /* Darker color on hover */
}

.error-message {
  color: red; /* Red color for error messages */
  text-align: center; /* Center error message */
  margin: 10px 0; /* Space above and below */
  padding: 10px; /* Padding for better spacing */
  border: 1px solid red; /* Red border for emphasis */
  border-radius: 4px; /* Rounded corners for a softer look */
  background-color: #ffe6e6; /* Light red background for visibility */
  font-size: 16px; /* Consistent font size */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}
