/* General Styles */
html, body {
    height: 100%;
    width: 100%;
    margin: 0;
    font-family: 'Arial', sans-serif;
    background-color: rgba(240, 240, 240, 0.7); /* Slightly transparent gray background */
}

.add-discount-container {
    width: 450px; /* Fixed width for pop-up */
    height: auto; /* Allow height to adjust based on content */
    padding: 20px; /* Reduced padding around the container */
    background-color: #fff; /* White background for the form */
    border-radius: 8px; /* Slightly rounded corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Slightly darker shadow */
    overflow-y: auto; /* Enable vertical scrolling */
    box-sizing: border-box; /* Include padding and border in width */
    position: fixed; /* Fixed positioning for pop-up */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust position to center */
    z-index: 1000; /* Ensure pop-up is above other content */
}

h2 {
    text-align: center; /* Center the heading */
    color: #333; /* Dark text color */
    margin-bottom: 15px; /* Space below the heading */
}

/* Discount Fields Container */
.discount-fields-container {
    max-height: 200px; /* Set a max height for discount fields */
    overflow-y: auto; /* Enable scrolling for the discount fields */
    margin-bottom: 15px; /* Space below discount fields */
}

/* Individual Discount Field */
.discount-field {
    margin-bottom: 10px; /* Space between fields */
    padding: 10px; /* Reduced padding inside each field */
    border: 1px solid #ccc; /* Light border around each field */
    border-radius: 5px; /* Rounded corners */
    background-color: #fafafa; /* Light background for each field */
}

/* Input Field Styling */
.input-field {
    margin-bottom: 10px; /* Space below input fields */
}

label {
    font-weight: bold; /* Bold label text */
    margin-bottom: 5px; /* Space below labels */
    display: block; /* Make labels block elements */
}

input[type="number"] {
    width: 100%; /* Full width for inputs */
    padding: 8px; /* Reduced padding inside inputs */
    border: 1px solid #ccc; /* Light border */
    border-radius: 4px; /* Rounded corners */
}

/* Separator Line */
.separator-line {
    border: none; /* Remove default border */
    height: 1px; /* Height of the separator line */
    background-color: #ddd; /* Light gray color */
    margin: 10px 0; /* Space above and below line */
}

/* Button Container */
.button-container {
    display: flex; /* Flexbox for button alignment */
    justify-content: space-between; /* Space between buttons */
}

/* Submit Button */
.submit-button {
    background-color: #28a745; /* Green background for submit */
    color: white; /* White text */
    border: none; /* Remove border */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor */
    padding: 8px 16px; /* Reduced padding for submit button */
    font-size: 14px; /* Smaller font size */
    transition: background-color 0.3s; /* Smooth transition */
}

.submit-button:hover {
    background-color: #218838; /* Darker green on hover */
}

/* Add More Button */
.add-more-button {
    background-color: #007bff; /* Blue background for add more */
    color: white; /* White text */
    border: none; /* Remove border */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor */
    padding: 8px 16px; /* Reduced padding for add more button */
    font-size: 14px; /* Smaller font size */
    transition: background-color 0.3s; /* Smooth transition */
}

.add-more-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
}

/* Error Message */
.error-message {
    color: red; /* Red color for error messages */
    text-align: center; /* Center error message */
    margin-bottom: 15px; /* Space below error message */
}

/* Add More button container */
.add-more-container {
    text-align: right; /* Align to the right */
    margin-bottom: 15px; /* Add spacing between Add More and Submit */
}

.submit-container {
    text-align: right; /* Keep submit button aligned right */
}

/* Rest of the CSS remains the same */
